import React, { useEffect, useState } from "react";

function ExternalProfile(props) {
    const [inEditMode, setInEditMode] = useState(false);

    function setDisabled(element, state) {
        if (!(element instanceof HTMLInputElement) && !(element instanceof HTMLSelectElement)) {
            return;
        }

        element.disabled = state;
    }

    useEffect(() => {
        document.querySelectorAll('.externalProfileWrapper input').forEach((element) => {
            setDisabled(element, !inEditMode)
        });
        document.querySelectorAll('.externalProfileWrapper select').forEach((element) => {
            setDisabled(element, !inEditMode)
        });
    }, [inEditMode]);
    
    return <div className="column ExternalProfile">
        <div className="externalProfileWrapper">
            <div className="header">
                { inEditMode && <div className="left">
                        <span className="cancle" onClick={() => {setInEditMode(!inEditMode)}}>Abbrechen</span>
                    </div> 
                }
                <h3>Kontaktdaten</h3>
                <div className="right" onClick={() => {setInEditMode(!inEditMode)}}>
                    { !inEditMode && <span className="edit">Bearbeiten</span> }
                    { inEditMode && <span className="edit">Fertig</span> }
                </div>
                <div className="imageWrapper">
                    <span>Foto hinzufügen</span>
                    <img className="" src="/icons/profileImage.png" alt="Profile"/>
                </div>
            </div>
            <div className="body">
                <div className="group">
                    <div className="row">
                        <div className="left">Anrede</div>
                        <div className="right">
                            <select name="salutation" id="salutation">
                                <option value="notSpecified">Keine Angaben</option>
                                <option value="female">Frau</option>
                                <option value="male" selected>Herr</option>
                                <option value="diverse">Divers</option>
                            </select>
                            <select name="title" id="title">
                                <option value="notSpecified">Keine Angaben</option>
                                <option value="Prof.">Prof.</option>
                                <option value="Dr.">Dr.</option>
                                <option value="Prof.Dr." selected>Prof.Dr.</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Vorname</div>
                        <div className="right">
                            <input type="text" name="firstname" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Nachname</div>
                        <div className="right">
                            <input type="text" name="lastname" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Firma</div>
                        <div className="right">
                            <input type="text" name="company" placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">Straße/Hausnr.</div>
                        <div className="right">
                            <input type="text" name="street" placeholder="" />
                            <input type="text" name="houseNumber" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">PLZ/Stadt</div>
                        <div className="right">
                            <input type="text" name="zip" placeholder="" />
                            <input type="text" name="city" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Land</div>
                        <div className="right">
                            <select name="country" id="country">
                                <option value="DE">Deutschland</option>
                                <option value="AU">Österreich</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">E-Mail</div>
                        <div className="right">
                            <input type="email" name="email" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Telefon</div>
                        <div className="right">
                            <input type="text" name="phone" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Mobil</div>
                        <div className="right">
                            <input type="text" name="mobile" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Homepage</div>
                        <div className="right">
                            <input type="text" name="website" placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">Notiz</div>
                        <div className="right"><textarea rows={5} /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ExternalProfile;