import React from "react";

import SearchBar from "../../modules/SearchBar";

function SentDocuments(props) {

    return <div className="sentDocumentsWrapper">
        <div className="sentDocumentsHeader" onClick={ () => {props.setView('chat')} }>
            <img className="backLink" src="/icons/chevron-left-solid.svg" alt="Icon" />
            <h3>Schließen</h3>
        </div>
        <div className="sentDocumentsBody">
            <div className="documentsWrapper">
                <div className="documentsHeader">
                    <h3>Gesendete Dokumente</h3>
                    <SearchBar />
                </div>
                <div className="documentsBody">
                    /// DOKUMENTE
                </div>
            </div>
            <div className="sentDocumentsSearch">
                <div>
                    <img src="/icons/search-button.svg" alt="Icon" />
                    <span>Chat durchsuchen</span>
                </div>
            </div>
        </div>
    </div>;
}

export default SentDocuments;