import React from "react";

import SearchBar from "../../modules/SearchBar";

function Documents() {

    return <div className="documentsWrapper">
        <div className="documentsHeader">
            <h3>Dokumente</h3>
            <div className="searchWrapper">
                <SearchBar />
                <img className="addIcon" src="/icons/plus.svg" alt="Icon" />
            </div>
        </div>
    </div>;
}

export default Documents;