import React, { useEffect } from "react";
import Faker from "@faker-js/faker";
import { v4 as uuidv4 } from 'uuid';

import SearchBar from "../../modules/SearchBar";
import Modal from "../../Modal";
import PersonalDataForm from "../../forms/PersonalDataForm";
import DataSubmissionForm from "../../forms/DataSubmissionForm";
import DataQueryForm from "../../forms/DataQueryForm";
import { type } from "os";

function randomDate(start: Date, end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function getAlphabet() {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    return alpha.map((x) => String.fromCharCode(x));
}

function getPatients() {
    let patients = [];

    for (let i = 0; i < 100; i++) {
        patients.push({
            key: i,
            firstName: Faker.name.firstName(),
            lastName: Faker.name.lastName(),
            patientNumber: Math.floor(1000 + Math.random() * 9000),
            status: 'Aktiv',
            createdAt: randomDate(new Date(2012, 0, 1), new Date()).toLocaleDateString()
        });
    }

    patients.sort((a, b) => a.firstName.localeCompare(b.firstName));

    return patients;
}

function generateAlphabeticalPatients(setView: Function) {

    let alphabeticPatients = [];

    getPatients().forEach(patient => {
        const letter = patient.firstName.charAt(0);

        if (!alphabeticPatients[letter]) {
            alphabeticPatients[letter] = [];
        }

        alphabeticPatients[letter].push(patient);
    });

    let returnValue = [];

    getAlphabet().forEach(letter => {
        if (!alphabeticPatients[letter]) {
            return false;
        }

        returnValue.push(<div id={"section-" + letter} className="groupLabel">{letter}</div>);
        returnValue.push(alphabeticPatients[letter].map(patient => {
            return <div key={patient.key} className="ny_row" onClick={() => {setView('patient')}}>
                <div className="ny_column _avatar"><img src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" /></div>
                <div className="ny_column _name">{patient.firstName} &nbsp;<strong>{patient.lastName}</strong></div>
                <div className="ny_column _number">{patient.patientNumber}</div>
                <div className="ny_column _date">{patient.createdAt}</div>
                <div className="ny_column _status">{patient.status}</div>
            </div>
        }));
    });

    return returnValue;
}

function Overview(props) {
    useEffect(() => {
        document.getElementById('generalDataCheck').addEventListener('change', (event) => {
            if (event.target.checked) {
                document.querySelectorAll('#ny-patient-page-data-query-modal .checkGroup input[type=checkbox]').forEach((checkbox) => {
                    checkbox.checked = true;
                });
            }
        });
    }, []);

    return <div className="Content PatientsPage Overview">
        {<Modal modalId="ny-patient-page-profile-modal">
            <PersonalDataForm />
        </Modal>}
        {<Modal modalId="ny-patient-page-data-submission-modal">
            <DataSubmissionForm />
        </Modal>}
        {<Modal modalId="ny-patient-page-data-query-modal">
            <DataQueryForm />
        </Modal>}

        <h1>Ihre Patienten</h1>
        <div className="PatientsPageMenu">
            <SearchBar />
            <button id="newPatientBtn" className="ny-modal-trigger" ny-target-modal="ny-patient-page-profile-modal" title="Hier können Sie einen neuen Patienten anlegen">Neuer Patient</button>
            <label htmlFor="hideInactivePatients">Inaktive Patienten ausblenden</label>
            <input id="hideInactivePatients" type="checkbox" defaultChecked={true}></input>
        </div>
        
        <div className="PatientsTable">
            <div className="tableHead ny_row">
                <div className="ny_column _avatar"></div>
                <div className="ny_column _name">Name</div>
                <div className="ny_column _number">Patienten-Nr.</div>
                <div className="ny_column _date">Anlegedatum</div>
                <div className="ny_column _status">Status</div>
            </div>
            <div className="tableBody">
                {generateAlphabeticalPatients(props.setView)}
            </div>
        </div>
    </div>;
}

export default Overview;