import React, { useState } from "react";


function PasswordChange(props) {
    return <div className="passwordChange">
        <h2>Passwort ändern</h2>
        <div className="group">
            <div className="row">
                <div className="left">Altes Passwort eingeben <br /><span>Letzte Änderung: 12.06.2020</span></div>
                <div className="right">
                    <input type="password" name="currentPassword" />
                    <span id="passwordForgotten" onClick={() => { props.setTabMode('passwordRecovery') }}>Passwort vergessen?</span>
                </div>
            </div>
        </div>
        <div className="group">
            <div className="row">
                <div className="left">Neues Passwort eingeben</div>
                <div className="right">
                    <input type="password" name="newPassword" />
                </div>
            </div>
            <div className="row">
                <div className="left">Neues Passwort wiederholen</div>
                <div className="right">
                    <input type="password" name="newPasswordRepeat" />
                    <button>Ändern</button>
                </div>
            </div>
        </div>
    </div>
}

function PasswordRecovery() {
    return <div className="passwordChange">
    <h2>Passwort ändern - Wiederherstellungspasswort</h2>
    <div className="group">
        <div className="row">
            <div className="left">Wiederherstellungspasswort</div>
            <div className="right"><input type="password" name="recoveryPassword" /></div>
        </div>
    </div>
    <div className="group">
        <div className="row">
            <div className="left">Neues Passwort eingeben</div>
            <div className="right">
                <input type="password" name="newPassword" />
            </div>
        </div>
        <div className="row">
            <div className="left">Neues Passwort wiederholen</div>
            <div className="right">
                <input type="password" name="newPasswordRepeat" />
                <button>Ändern</button>
            </div>
        </div>
    </div>
</div>
}

function Security() {
    const [tabMode, setTabMode] = useState('passwordChange');

    return <div className="tab Security">
        { tabMode === 'passwordChange' && <PasswordChange setTabMode={setTabMode} /> }
        { tabMode === 'passwordRecovery' && <PasswordRecovery /> }
    </div>;
}

export default Security;