import React from "react";

import SearchBar from "../../modules/SearchBar";
import Modal from "../../Modal";
import NewChatForm from "./NewChatForm";
import NewGroupChatForm from "./NewGroupChatForm";

function List(props) {
    return <div className="column List">
        <div className="switchWrapper">
            <div id="modeSwitch" className="switch">
                <span onClick={ () => {props.setListMode('experts')} } className={ props.listMode === 'experts' ? 'active' : ''}>Experten</span>
                <span onClick={ () => {props.setListMode('patients'); props.setViewMode('chat');} } className={ props.listMode === 'patients' ? 'active' : ''}>Patienten</span>
            </div>
        </div>
        <div className="listWrapper">
            {<Modal modalId="ny-chat-page-add-modal">
                <div className="ny-modal-trigger" ny-target-modal="ny-chat-page-new-group-chat-modal">
                    <span>Neue Gruppe</span>
                    <img className="infoIcon" src="/icons/persons.svg" alt="Icon"/>
                </div>
                <div className="ny-modal-trigger" ny-target-modal="ny-chat-page-new-chat-modal">
                    <span>Neuer Kontakt</span>
                    <img className="infoIcon" src="/icons/person.svg" alt="Icon"/>
                </div>
            </Modal>}
            {<Modal modalId="ny-chat-page-new-chat-modal">
                <NewChatForm />
            </Modal>}
            {<Modal modalId="ny-chat-page-new-group-chat-modal">
                <NewGroupChatForm />
            </Modal>}

            <div className="listHeader">
                <h3>Nachrichten</h3>

                { props.listMode === 'experts' && 
                    <div>
                        <img onClick={ () => {props.viewMode === 'chat' ? props.setViewMode('invitations') : props.setViewMode('chat')} } className="notificationIcon" src="/icons/notification.svg" alt="Icon"/>
                        <img className="plusIcon ny-modal-trigger" ny-target-modal="ny-chat-page-add-modal" src="/icons/plus.svg" alt="Icon"/>
                        {/* <p className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-patient-page-profile-modal">&lt; Abbrechen</p> */}
                    </div> 
                }
                                
            </div>
            <div className="listBody">
                <div className="listSearch">
                    <SearchBar />
                </div>
            </div>
        </div>
    </div>;
}

export default List;