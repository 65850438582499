import React, { useState, useEffect } from "react";

import List from "./pages/contactPage/List";
import Profile from "./pages/contactPage/Profile";
import ExternalProfile from "./pages/contactPage/ExternalProfile";
import Invitations from "./pages/chatPage/Invitations";
import Groups from "./pages/contactPage/Groups";

function ContactPage() {
    const [listMode, setListMode] = useState('internals');
    const [viewMode, setViewMode] = useState('profile');

    useEffect(() => {
        setViewMode('profile');
    }, [listMode]);

    return <div className="Content ContactPage">
        <List listMode={listMode} setListMode={setListMode} viewMode={viewMode} setViewMode={setViewMode} />
        { viewMode === 'profile' && listMode === 'internals' && <Profile /> }
        { viewMode === 'profile' && listMode === 'externals' && <ExternalProfile /> }
        { viewMode === 'invitations' && <Invitations /> }
        { viewMode === 'profile' && listMode === 'internals' && <Groups /> }
    </div>;
}

export default ContactPage;