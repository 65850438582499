import React from "react";

import SearchBar from "../../modules/SearchBar";
import Modal from "../../Modal";

function NibyouDocuments() {

    function invoiceHandler(e) {
        document.querySelectorAll('.NibyouDocuments .invoice').forEach((member) => {
            member.classList.remove('active');
        
            if (member === e.currentTarget) {
                member.classList.add('active');
            }
        });
    }

    return <div className="tab NibyouDocuments">
        {<Modal modalId="ny-settings-page-data-modal">
            <div>
                <div className="modal-header">
                    <h2>Ihre aktuellen Vertragsdaten</h2>
                    <button className="ny-modal-trigger continue" ny-target-modal="ny-settings-page-data-modal">Fertig</button>
                </div>
                <div className="modal-main">
                    <div className="row">
                        <span>AGB:</span>
                        <a href="www.link.de/stehthieragbtralalalalalaaajcdsds">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
                    </div>
                    <div className="row">
                        <span>AVV:</span>
                        <a href="www.link.de/stehthieragbtralalalalalaaajcdsds">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
                    </div>
                    <div className="row">
                        <span>Leistungsbeschreibung:</span>
                        <a href="www.link.de/stehthieragbtralalalalalaaajcdsds">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
                    </div>
                    <div className="row">
                        <span>Preismodell:</span>
                        <a href="www.link.de/stehthieragbtralalalalalaaajcdsds">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
                    </div>
                </div>
            </div>
        </Modal>}

        <div className="listWrapper">
            <div className="header">
                <h3>Nibyou Dokumente</h3>
                <div>
                    <SearchBar />
                    <button className="ny-modal-trigger" ny-target-modal="ny-settings-page-data-modal">Vertragsdaten</button>
                </div>
            </div>
            <div className="body">
                <div className="invoice new" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice active" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
                <div className="invoice" onClick={(e) => {invoiceHandler(e)}}>
                    <div>
                        <span className="date">31.01.2021</span>
                        <span className="title">Rechnung</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="detailWrapper">
            <div>
                <img src="/icons/file.svg" alt="Logo" />
            </div>
        </div>
    </div>;
}

export default NibyouDocuments;