import React from "react";

function Profile(props) {
    return <div className="column Profile">
        <div className="profileWrapper">
            <div className="header">
                <h2>Prof. Dr. Christian Carnevale</h2>
                <div className="imageWrapper">
                    <img className="" src="/icons/profileImage.png" alt="Profile"/>
                    <p>Vernetzt seit dem 31.01.2021</p>
                </div>

                <div className="messageWrapper">
                    <img className="" src="/icons/chat_white.svg" alt="Chat"/>
                    <p>Nachricht</p>
                </div>
            </div>
            <div className="body">
                <div className="group">
                    <div className="row">
                        <div className="left">Firma</div>
                        <div className="right">Christian Carnevale Ernährungsberatung</div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">E-Mail</div>
                        <div className="right">c.carnevale@ernährung.de</div>
                    </div>
                    <div className="row">
                        <div className="left">Telefon</div>
                        <div className="right">+49 (0) 8102 999999</div>
                    </div>
                    <div className="row">
                        <div className="left">Mobil</div>
                        <div className="right">+49 (0) 171 1111111</div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">Straße/Hausnr.</div>
                        <div className="right">Musterstraße 1</div>
                    </div>
                    <div className="row">
                        <div className="left">PLZ/Stadt</div>
                        <div className="right">12345 Musterhausen</div>
                    </div>
                    <div className="row">
                        <div className="left">Land</div>
                        <div className="right">Deutschland</div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">Homepage</div>
                        <div className="right"><a href="https://k-b.dev" target="_blank" rel="noreferrer">https://k-b.dev</a></div>
                    </div>
                </div>
                <div className="group">
                    <div className="row">
                        <div className="left">Notiz</div>
                        <div className="right"><textarea rows={5} /></div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <img className="" src="/icons/trash.svg" alt="Icon"/>
                <img className="" src="/icons/save.svg" alt="Icon"/>
            </div>
        </div>
    </div>;
}

export default Profile;