import React from "react";

function NewGroupChatForm() {
    return <div>
        <div className="modal-header">
            <button type="button" className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-chat-page-new-group-chat-modal" ny-open-modal="ny-chat-page-add-modal">&lt; Abbrechen</button>
            <h2>Neuer Gruppenchat</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-chat-page-new-group-chat-modal">Fertig</button>
        </div>

        <div className="modal-main">
            <div className="row">
                <div className="left">
                    <label>Gruppenname:</label>
                </div>
                <div className="right">
                    <input type="text" name="groupName" id="groupName" />
                </div>
            </div>
            <div className="row">
                <div className="left">Rederechte:</div>
                <div className="right">
                    <select name="permission" id="permission">
                        <option value="noone">Niemand</option>
                        <option value="all">Alle</option>
                    </select>
                </div>
            </div>
            <div className="group">
                <div className="row">
                    <div className="left">Empfänger eintragen:</div>
                    <div className="right">
                        <input type="text" name="receiver" id="receiver" placeholder="Empfängername eintragen…" />
                        <img className="addIcon" src="/icons/plus.svg" alt="Icon"/>
                    </div>
                </div>
            </div>
            <div className="group members">
                <div className="row">
                    <div className="right">
                        <input type="text" placeholder="" value="Mitgliedername | ID" />
                        <img className="addIcon" src="/icons/unlink.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right">
                        <input type="text" placeholder="" value="Mitgliedername | ID" />
                        <img className="addIcon" src="/icons/unlink.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right">
                        <input type="text" placeholder="" value="Mitgliedername | ID" />
                        <img className="addIcon" src="/icons/unlink.svg" alt="Icon"/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default NewGroupChatForm;