import React from "react";

function SearchBar() {
    return <div className="ny_searchBar-wrapper">
        <div className="ny_searchBar">
            <img className="searchIcon lense" src="/icons/search-lense_gray.svg" alt="Icon" />
            <input className="searchBox" type="text" name="search" placeholder=""></input>
            <img className="searchIcon cross" src="/icons/cross.svg" alt="Icon" />
        </div>
        <img className="ny_searchBar-button" src="/icons/search-button.svg" alt="Icon" />
    </div>;
}

export default SearchBar;