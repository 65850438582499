import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

function resizeTextArea(e: KeyboardEvent) {
    if (!(e.target instanceof HTMLTextAreaElement)) {
        return;
    }

    const text = e.target.value;
    const lines = text.split("\n");
    const linesCount = lines.length;

    if (linesCount > 6) {
        e.target.style.overflowY = 'scroll';
    } else {
        e.target.style.overflowY = 'hidden';
        e.target.style.height = (linesCount * 20) + "px";
    }


    // if (e.target.scrollHeight > 120) {
    //     e.target.style.overflowY = 'scroll';
    // }
    
    // if (e.target.scrollHeight < 120) {
    //     e.target.style.overflowY = 'hidden';

    //     e.target.style.height = 24 + "px";
    //     let newHeight = (25 + e.target.scrollHeight);

    //     if (newHeight > 49) {
    //         e.target.style.height = (12 + e.target.scrollHeight) + "px";
    //     }
    // }
}

function ResizeAbleTextArea(props) {
    let id = '';

    if (props.id) {
        id = props.id;
    } else {
        id = 'textarea' + uuidv4();
    }

    useEffect(() => {
        const element = document.getElementById(id);

        if (!(element instanceof HTMLTextAreaElement)) return;
        
        element.addEventListener('keyup', (e) => {
            resizeTextArea(e);
        });
    }, []);

    return <textarea rows={props.rows ?? 1} name={props.name} id={id} placeholder={props.placeholder ?? ''}></textarea>
}

export default ResizeAbleTextArea;