import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import Layout from './Layout';
import PatientsPage from './PatientsPage';
import ChatPage from './ChatPage';
import ContactPage from './ContactPage';
import SettingsPage from './SettingsPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ProtectedRoutes from './ProtectedRoutes';
import Logout from './Logout';

function NotFoundPage() {
    return <div className='NotFoundPage'>
        <div>
            <h1>404 Seite nicht gefunden</h1>
            <p>
                Lass dich nicht entmutigen, wahrscheinlich haben wir einen Fehler gemacht. <br />
                Geh am besten zurück zur
            </p>
            <Link to="/">
                Homepage
            </Link>
        </div>
    </div>;
}

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="logout" element={<Logout />} />
                <Route path="*" element={<NotFoundPage />} />

                <Route element={<ProtectedRoutes />}>
                    <Route element={<Layout />}>
                        <Route index element={<PatientsPage />} />
                        <Route path="home" element={<PatientsPage />} />
                        <Route path="patients" element={<PatientsPage />} />
                        <Route path="chat" element={<ChatPage />} />
                        <Route path="contact" element={<ContactPage />} />
                        <Route path="settings" element={<SettingsPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;