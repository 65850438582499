
const DataQueryForm = () => {
    function handleGeneralDataCheck(e) {
        const checked = e.target.checked;

        document.querySelectorAll('#ny-patient-page-data-query-modal input[type=checkbox]').forEach((element) => {
            element.checked = checked
        });
    }
    return <form>
        <div className="modal-header">
            <button type="button" className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-patient-page-data-query-modal" ny-open-modal="ny-patient-page-data-submission-modal">&lt; Abbrechen</button>
            <h2>Datenabfrage</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-patient-page-data-query-modal">Fertig</button>
        </div>

        <div className="modal-main">
            <label htmlFor="generalDataCheck">Allgemeine Daten</label>
            <input type="checkbox" onClick={(e) => {handleGeneralDataCheck(e)}} name="generalData" id="generalDataCheck" />

            <div className="checkGroup">
                <label htmlFor="salutation">Anrede</label>
                <input type="checkbox" name="salutation" id="salutation" />
            </div>

            <div className="checkGroup">
                <label htmlFor="firstname">Vorname</label>
                <input type="checkbox" name="firstname" id="firstname" />
            </div>

            <div className="checkGroup">
                <label htmlFor="lastname">Nachname</label>
                <input type="checkbox" name="lastname" id="lastname" />
            </div>

            <div className="checkGroup">
                <label htmlFor="biologicalGender">Bio. Geschlecht</label>
                <input type="checkbox" name="biologicalGender" id="biologicalGender" />
            </div>

            <div className="checkGroup">
                <label htmlFor="genderIdentity">Geschlechtsidentität</label>
                <input type="checkbox" name="genderIdentity" id="genderIdentity" />
            </div>

            <div className="checkGroup">
                <label htmlFor="dateofbirth">Geburtstag</label>
                <input type="checkbox" name="dateofbirth" id="dateofbirth" />
            </div>

            <div className="checkGroup">
                <label htmlFor="healthInsurance">Versichertennummer &amp; Krankenkasse</label>
                <input type="checkbox" name="healthInsurance" id="healthInsurance" />
            </div>

            <div className="checkGroup">
                <label htmlFor="address">Adresse</label>
                <input type="checkbox" name="address" id="address" />
            </div>
            <div className="checkGroup">
                <label htmlFor="phone">Telefon / Handy</label>
                <input type="checkbox" name="phone" id="phone" />
            </div>

            <div className="checkGroup">
                <label htmlFor="needCertificate">Notwendigkeitsbescheinigung</label>
                <input type="checkbox" name="needCertificate" id="needCertificate" />
            </div>
        </div>
    </form>
}

export default DataQueryForm;