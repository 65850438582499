import React from "react";

function Groups() {
    return <div className="column Groups">
        <div className="groupsWrapper">
            <h3>Deine Gruppenchats</h3>

            <div>
                <input type="text" placeholder="Gruppenname" />
                <img src="/icons/plus.svg" alt="Icon"/>
            </div>
            <div>
                <input type="text" value="Gruppenname" disabled />
                <img src="/icons/trash.svg" alt="Icon"/>
            </div>
            <div>
                <input type="text" value="Gruppenname" disabled />
                <img src="/icons/trash.svg" alt="Icon"/>
            </div>
        </div>
    </div>;
}

export default Groups;