import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LoginPage from './LoginPage';


function ProtectedRoutes(props) {
    const [isAuthenticated, setIsAuthenticated] = useState((localStorage.refreshToken !== undefined) && (localStorage.refreshToken !== ''));

    if (isAuthenticated) {
        return <Outlet />
    }

    return <LoginPage setIsAuthenticated={setIsAuthenticated} />;
}

export default ProtectedRoutes;