import React, { useState, useEffect } from "react";

import List from "./pages/chatPage/List";
import Chat from "./pages/chatPage/Chat";
import Invitations from "./pages/chatPage/Invitations";
import ChatInfo from "./pages/chatPage/ChatInfo";
import Profile from "./pages/chatPage/Profile";

function ChatPage() {
    const [listMode, setListMode] = useState('experts');
    const [viewMode, setViewMode] = useState('chat');
    const [showProfile, setShowProfile] = useState(false);

    useEffect(() => {
        if (viewMode !== 'chatInfo') {
            setShowProfile(false);
        }
    }, [viewMode]);

    return <div className="Content ChatPage">
        <List listMode={listMode} setListMode={setListMode} viewMode={viewMode} setViewMode={setViewMode}/>
        { viewMode === 'chat' && <Chat viewMode={viewMode} setViewMode={setViewMode} setShowProfile={setShowProfile} /> }
        { viewMode === 'invitations' && <Invitations /> }
        { viewMode === 'chatInfo' && <ChatInfo setViewMode={setViewMode} /> }
        { showProfile && <Profile listMode={listMode} /> }
    </div>;
}

export default ChatPage;