
const DataSubmissionForm = () => {
    return <form>
        <div className="modal-header">
            <button type="button" className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-patient-page-data-submission-modal" ny-open-modal="ny-patient-page-profile-modal">&lt; Abbrechen</button>
            <h2>Aufforderung Einmalige Datenabgabe</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-patient-page-data-query-modal">Weiter</button>
        </div>

        <div className="modal-main">
            <p>E-Mail formulieren:</p>
            <textarea  rows={12}></textarea>
            <div className="data-submission-save-wrapper">
                <label htmlFor="data-submission-save-text">Formulierung speichern</label>
                <input type="checkbox" id="data-submission-save-text" />
            </div>
        </div>

        <div className="modal-footer">
            <p>Dokument mitschicken:</p>
            <div className="ny-file-icon-wrapper">
                <img className="removeIcon" src="/icons/cross_red.svg" alt="Icon" />
                <img className="fileIcon" src="/icons/file.svg" alt="Icon" />
                <span className="ny-file-title">Titel</span>
            </div>
            <img className="addFileIcon" src="/icons/plus.svg" alt="Icon" />
        </div>

        
        

        <div className="ny-modal-content_underline"></div>
    </form>
}

export default DataSubmissionForm;