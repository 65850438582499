import React, { useState, useEffect } from "react";

import Overview from "./pages/patientPage/Overview";
import Patient from "./pages/patientPage/Patient";

function PatientsPage() {
    const [view, setView] = useState('overview');
    
    useEffect(() => {
        return () => {
            setView('overview');
        };
    }, []);

    if (view === 'overview') {
        return <Overview setView={setView} />;
    }

    if (view === 'patient') {
        return <Patient setView={setView} />;
    }
}

export default PatientsPage;