import React from "react";

import SearchBar from "../../modules/SearchBar";
import Modal from "../../Modal";

function NewChatForm() {
    return <div>
        <div className="modal-header">
            <button type="button" className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-contact-page-new-chat-modal" ny-open-modal="ny-chat-page-add-modal">&lt; Abbrechen</button>
            <h2>Neuer Kontakt</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-contact-page-new-chat-modal">Fertig</button>
        </div>

        <div className="modal-main">
            <label htmlFor="name">Empfänger eintragen:</label>
            <input type="text" name="name" id="name" placeholder="ID eintragen…" />
        </div>
    </div>;
}

function List(props) {

    return <div className="column List">
        <div className="switchWrapper">
            <div id="modeSwitch" className="switch">
                <span onClick={ () => {props.setListMode('internals')} } className={ props.listMode === 'internals' ? 'active' : ''}>Experten&nbsp;(Nibyou)</span>
                <span onClick={ () => {props.setListMode('externals')} } className={ props.listMode === 'externals' ? 'active' : ''}>Externe&nbsp;Kontakte</span>
            </div>
        </div>
        <div className="listWrapper">

            <div className="listHeader">
                <h3>Kontakte</h3>
                <div>
                    { props.listMode === 'internals' && <img className="infoIcon" onClick={ () => {props.viewMode === 'profile' ? props.setViewMode('invitations') : props.setViewMode('profile')} } src="/icons/notification.svg" alt="Icon"/> }
                    <img className="plusIcon ny-modal-trigger" ny-target-modal="ny-contact-page-new-chat-modal" src="/icons/plus.svg" alt="Icon"/>
                    {<Modal modalId="ny-contact-page-new-chat-modal">
                        <NewChatForm />
                    </Modal>}
                </div>                
            </div>
            <div className="listBody">
                <div className="listSearch">
                    <SearchBar />
                </div>
            </div>
        </div>
    </div>;
}

export default List;