import React from "react";
import { Link } from "react-router-dom";

function SideNav() {

    return <div className="ny_sideNav__wrapper">
        <nav className="ny_sideNav">
            <Link to="/patients" className="ny_sideNaveItem">
                <img src="/icons/hand.svg" alt="Logo" />
                <span>Patienten</span>
            </Link>
            <Link to="/chat" className="ny_sideNaveItem">
                <img src="/icons/chat.svg" alt="Logo" />
                <span>Nachrichten</span>
            </Link>
            <Link to="/contact" className="ny_sideNaveItem">
                <img src="/icons/contact.svg" alt="Logo" />
                <span>Kontakte</span>
            </Link>
            <Link to="/settings" className="ny_sideNaveItem">
                <img src="/icons/profile_nav.svg" alt="Logo" />
                <span>Einstellungen</span>
            </Link>
        </nav>
    </div>;
}

export default SideNav;