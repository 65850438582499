import React, { useEffect } from "react";
import SearchBar from "../../modules/SearchBar";

function ChatInfo(props) {
    function membersHandler(e) {
        document.querySelectorAll('.ChatInfo .member').forEach((member) => {
            member.classList.remove('selected');
        
            if (member === e.currentTarget) {
                member.classList.add('selected');
            }
        });
    }

    return <div className="column ChatInfo">
        <div className="chatInfoWrapper">
            <div className="chatInfoHeader" onClick={ () => {props.setViewMode('chat')} }>
                <img className="backLink" src="/icons/chevron-left-solid.svg" alt="Icon" onClick={() => {props.setView('overview')}}/>
                <h3>Schließen</h3>
            </div>
            <div className="chatInfoBody">
                <div className="documentsWrapper">
                    <div className="documentsHeader">
                        <h3>Gesendete Dokumente</h3>
                        <SearchBar />
                    </div>
                    <div className="documentsBody">
                        /// DOKUMENTE
                    </div>
                </div>
                <div className="chatInfoSearch">
                    <div>
                        <img src="/icons/search-button.svg" alt="Icon" />
                        <span>Chat durchsuchen</span>
                    </div>
                </div>
                <div className="membersWrapper">
                    <div className="actionButton">
                        <img src="/icons/plus.svg" alt="Icon" />
                        <span>Teilnehmer hinzufügen</span>
                    </div>
                    <div className="actionButton">
                        <img src="/icons/link.svg" alt="Icon" />
                        <span>Mit Link zur Gruppe einladen</span>
                    </div>
                    <div className="list">
                        <div className="member" onClick={(e) => {membersHandler(e)}}>
                            <span>Nicolas Müller | Sie</span><span className="admin">Admin</span>
                        </div>
                        <div className="member selected" onClick={(e) => {membersHandler(e)}}>
                            <span>Dr. Dieter Reihnland | vernetzt</span>
                        </div>
                        <div className="member" onClick={(e) => {membersHandler(e)}}>
                            <span>Mitgliedernamen </span>
                        </div>
                    </div>
                    <span className="showAll">Alle anzeigen</span> 
                </div>
            </div>
        </div>
    </div>;
}

export default ChatInfo;