import React from "react";

function NewChatForm() {
    return <div>
        <div className="modal-header">
            <button type="button" className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-chat-page-new-chat-modal" ny-open-modal="ny-chat-page-add-modal">&lt; Abbrechen</button>
            <h2>Neuer Chat</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-chat-page-new-chat-modal">Fertig</button>
        </div>

        <div className="modal-main">
            <label htmlFor="name">Empfänger eintragen:</label>
            <input type="text" name="name" id="name" />
        </div>
    </div>;
}

export default NewChatForm;