import React from "react";
import { v4 as uuidv4 } from 'uuid';
import ResizeAbleTextArea from "../../modules/ResizeAbleTextArea";

function Chat(props) {

    function handleChatInfo() {
        props.setViewMode('chatInfo');
        props.setShowProfile(true);
    }

    return <div className="column Chat">
        <div className="chatWrapper">
            <div className="chatHeader">
                <img src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" />
                <span>Vorname und -Nachname</span>
                <div>
                    <img className="cameraIcon" src="/icons/camera.svg" alt="Icon"/>
                    <img onClick={ () => {handleChatInfo()} } className="infoIcon" src="/icons/info-circle-solid.svg" alt="Icon"/>
                </div>
            </div>
            <div className="chatBody">
            </div>
            <div className="chatFooter">
                <ResizeAbleTextArea name={"message"} placeholder={"Schreiben..."} /> 
                <div className="chatFileUpload">
                    <img src="/icons/image-regular.svg" alt="Icon" />
                </div>
            </div>
        </div>
    </div>;
}

export default Chat;