import React from "react";
import Faker from "@faker-js/faker";
import { v4 as uuidv4 } from 'uuid';

import Modal from "../../Modal";

function GroupList() {
    return <div className="content">
        <p>Gruppenname 1</p>
        <p>Gruppenname 2</p>
    </div>
}

function AddToGroupModal() {
    return <div className="content">
        <input type="text" name="groupName" placeholder="Gruppenname"/>
        <img className="addIcon" src="/icons/plus.svg" alt="Icon"></img>
    </div>
}

function Profile(props) {
    return <div className="column Profile">
        <div className="profileWrapper">
            <div className="profileHeader">
            </div>
            <div className="profileImage">
                <img src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" />
                <h3>{ Faker.name.firstName() } { Faker.name.lastName() }</h3>
            </div>
            <div className="body">
                <p>d.reihnland@ernährung.de</p>
                <p>+49 (0) 171 1234567</p>
                <p>
                    Musterstraße 13 <br />
                    12345 Musterstadt <br />
                    Deutschland
                </p>
                <br />
                { props.listMode === 'experts' && <div>
                        <div className="groupListWrapper">
                            <p className="ny-modal-trigger" ny-target-modal="ny-chat-page-profile-group-list-modal">Gemeinsame Gruppen ( 2 )</p>
                            {<Modal modalId="ny-chat-page-profile-group-list-modal">
                                <GroupList />
                            </Modal>}
                        </div>
                    
                        <div className="addToGroupWrapper">
                            <p className="ny-modal-trigger" ny-target-modal="ny-chat-page-profile-add-to-group-modal">Zu anderen Gruppen hinzufügen</p>
                            {<Modal modalId="ny-chat-page-profile-add-to-group-modal">
                                <AddToGroupModal />
                            </Modal>}
                        </div>
                    </div>
                }
                
            </div>
        </div>
    </div>;
}

export default Profile;