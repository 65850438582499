import React from "react";
import { Link } from "react-router-dom";

import Modal from "./Modal";

function helpModal() {
    return <Modal modalId="ny-header-help-modal">
        <div className="modal-header">
            <h2>Hilfe</h2>
            <button className="ny-modal-trigger continue" ny-target-modal="ny-header-help-modal">Fertig</button>
        </div>
        <div className="modal-main">
            <table>
                <tr>
                    <td>Hilfeforum:</td>
                    <td><a href="www.link.de/stehthieragbtralalalalalaaajcdsds" target="_blank">www.link.de/stehthieragbtralalalalalaaajcdsds</a></td>
                </tr>
                <tr>
                    <td>Support E-Mail:</td>
                    <td><a href="mailto:support@wirsindnichterreichbar.de">support@wirsindnichterreichbar.de</a></td>
                </tr>
                <tr>
                    <td>Support Hotline:</td>
                    <td><a href="tel:+49251000000000">+49 (0) 251 000000000</a></td>
                </tr>
            </table>
        </div>
    </Modal>
}

function Header() {

    return <header className="ny_header">
        {helpModal()}

        <img src="/icons/nibyou.svg" alt="Logo"/>
        <div className="ny_header__right">
            <Link to="/logout" className="ny_sideNaveItem">
                <img src="/icons/logout.svg" alt="Logo"/>
                <span>Ausloggen</span>
            </Link>
            <Link to="/logout" className="ny_sideNaveItem ny-modal-trigger" ny-target-modal="ny-header-help-modal">
                <img src="/icons/help.svg" alt="Logo"/>
                <span>Hilfe</span>
            </Link>
        </div>
    </header>;
}

export default Header;