import React, { useState } from "react";
import { login } from '@nibyou/auth-connector';

function userIsAuthenticated() {
    if (localStorage.getItem('refreshToken')) {
        return true;
    }

    return false;
}

async function handleSubmit(props) {
    const email = props.email;
    const password = props.password;
    const submitBtn = document.getElementById('submit');

    submitBtn.disabled = false;

    login(email, password).then((response) => {
        if (!userIsAuthenticated()) {
            return;
        }
    
        if (props.setIsAuthenticated) {
            props.setIsAuthenticated(true);
        } else {
            window.location.href = "/";
            
        }
    });

    // If failure:
    // submitBtn.disabled = true;
    // props.setLoginFailed(true)
}

function LoginPage(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);

    if (userIsAuthenticated()) {
        window.location.href = "/";
    }

    return <div className="Content LoginPage">
        <header className="header">
            <img src="/icons/nibyou_big.svg" alt="Logo" />
        </header>
        <h1>Login</h1>
        <form id="loginForm" onSubmit={(e) => {
            e.preventDefault();
            handleSubmit({
                email: email,
                password: password,
                setIsAuthenticated: props.setIsAuthenticated
            })
        }}>
            { loginFailed && 
                <p id="loginErrorMessage">Login leider nicht erfolgreich. <br /> Bitte überprüfen Sie Ihre Zugangsdaten.</p>
            }
            <input type="email" placeholder="E-Mail" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Passwort" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
            <button id="submit" type="button" onClick={() => handleSubmit({
                email: email,
                password: password,
                setIsAuthenticated: props.setIsAuthenticated,
                setLoginFailed: setLoginFailed
            })}>Login
            </button>
        </form>
        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>;
}

export default LoginPage;