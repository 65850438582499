import React from "react";
import ResizeAbleTextArea from "../../modules/ResizeAbleTextArea";

function Chat(props) {

    return <div className="chatWrapper">
        <div className="chatHeader">
            <h3>Nachrichten</h3>
            <img className="infoIcon" onClick={ () => {props.setView('sentDocuments')} } src="/icons/info-circle-solid.svg" alt="Icon"/>
            <img className="cameraIcon" src="/icons/camera.svg" alt="Icon"/>
        </div>
        <div className="chatBody">
        </div>
        <div className="chatFooter">
            <ResizeAbleTextArea name={"message"} placeholder={"Schreiben..."} /> 
            <div className="chatFileUpload">
                <img src="/icons/image-regular.svg" alt="Icon" />
            </div>
        </div>
    </div>;
}

export default Chat;