import React, { useState } from "react";

import UserData from './pages/settingsPage/UserData';
import AffiliateProgram from './pages/settingsPage/AffiliateProgram';
import NibyouDocuments from './pages/settingsPage/NibyouDocuments';
import Security from './pages/settingsPage/Security';

function getTabs(): Array<String> {
    const tabs = [];

    tabs['userData'] = 'Nutzerdaten';
    tabs['affiliateProgram'] = 'Affiliate-Programm';
    tabs['nibyouDocuments'] = 'Nibyou Dokumente';
    tabs['security'] = 'Sicherheit';

    return tabs;
}

function HeaderNavigation(props) {
    return <div className="headerNavigation">
        { Object.entries(getTabs()).map((key, value) => {
            const active = props.tab === key[0] ? 'active' : '';

            return <div onClick={ () => {props.setTab(key[0])} } className={active}>{ key[1] }</div>
        })}
    </div>
}

function SettingsPage() {
    const [tab, setTab] = useState('userData');

    return <div className="Content SettingsPage">
        <HeaderNavigation tab={tab} setTab={setTab} />
        <div className="body">
            { tab === 'userData' && <UserData /> }
            { tab === 'affiliateProgram' && <AffiliateProgram /> }
            { tab === 'nibyouDocuments' && <NibyouDocuments /> }
            { tab === 'security' && <Security /> }
        </div>
    </div>;
}

export default SettingsPage;