import React, { useEffect } from "react";

function Modal(props: any) {
    const attributes = {
        listner: 'ny-listener',
        targetModal: 'ny-target-modal',
        modalToOpen: 'ny-open-modal'
    }

    const selectors = {
        modal: '.ny-modal',
        modalTrigger: '.ny-modal-trigger',
        modalCloser: '.ny-modal-close',
    }

    useEffect(() => {
        addModalVisibilityToggler();
    }, []);

    function addModalVisibilityToggler() {
        const modalTriggers = document.querySelectorAll(selectors.modalTrigger);

        modalTriggers.forEach((element) => {
            if (element.hasAttribute(attributes.listner)) {
                return;
            }

            let modalId = element.getAttribute(attributes.targetModal);
            let targetModal = document.getElementById(modalId);

            if (!targetModal) {
                console.log('Target modal not found. Id: ' + modalId);
                return;
            }
            
            element.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();

                if (targetModal.classList.contains('visible')) {
                    targetModal.classList.remove('visible');
                }
                else {
                    hideAllOtherModals(element.getAttribute(attributes.targetModal));
                    targetModal.classList.add('visible');
                    scrollToElement(targetModal);
                }

                if (element.hasAttribute(attributes.modalToOpen)) {
                    const modalToOpenId = element.getAttribute(attributes.modalToOpen);
                    const modalToOpen = document.getElementById(modalToOpenId);

                    if (!modalToOpen) {
                        console.log('Target modal not found. Id: ' + modalToOpenId);
                        return;
                    }

                    modalToOpen.classList.add('visible');
                    scrollToElement(modalToOpen);
                }
            });

            element.setAttribute(attributes.listner, true);
        });
    }

    function scrollToElement(element: Element) {
        element.scrollIntoView({
            behavior: 'smooth'
        });
    }

    function hideAllOtherModals(targetModal: string) {
        document.querySelectorAll(selectors.modal).forEach((element) => {
            if (element.id !== targetModal) {
                element.classList.remove('visible');
            }
        });
    }

    function hideAllModals() {
        document.querySelectorAll(selectors.modal).forEach((element) => {
            element.classList.remove('visible');
        });
    }

    const modalId = props.modalId ?? '';
    
    if (!props.children) {
        return;
    }

    window.onclick = function(event) {
        if (!event.target.closest('.ny-modal-content')) {
            hideAllModals();
        }
    }

    return <div id={modalId} className="ny-modal">
        <div className="ny-modal-content">
            {props.children}
        </div>
    </div>;
}

export default Modal;