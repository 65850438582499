import React, { useState, useEffect } from "react";
import Faker from "@faker-js/faker";
import { v4 as uuidv4 } from 'uuid';

import Chat from "./Chat";
import SentDocuments from "./SentDocuments";
import Documents from "./Documents";
import Modal from "../../Modal";
import PersonalDataForm from "../../forms/PersonalDataForm";
import DataSubmissionForm from "../../forms/DataSubmissionForm";
import DataQueryForm from "../../forms/DataQueryForm";

function Patient(props) {
    const [view, setView] = useState('chat');
    
    useEffect(() => {
        return () => {
            setView('chat');
        };
    }, []);

    return <div className="Content PatientsPage Patient">
        {<Modal modalId="ny-patient-page-profile-modal">
            <PersonalDataForm />
        </Modal>}
        {<Modal modalId="ny-patient-page-data-submission-modal">
            <DataSubmissionForm />
        </Modal>}
        {<Modal modalId="ny-patient-page-data-query-modal">
            <DataQueryForm />
        </Modal>}

        <div className="profileWrapper">
            <img className="backLink" src="/icons/chevron-left-solid.svg" alt="Icon" onClick={() => {props.setView('overview')}}/>
            <img className="profileImage" src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" />
            <div className="userInfo">
                <h2>{ Faker.name.firstName() } { Faker.name.lastName() }</h2>
                <span>aktiv | Ohne Anbindung</span>
            </div>
            <img className="iconExternalLink ny-modal-trigger" ny-target-modal="ny-patient-page-profile-modal" src="/icons/external-link-solid.svg" alt="Icon" />
        </div>
        <div className="contentWrapper">
            { view === 'chat' && <Chat setView={setView} /> }
            { view === 'sentDocuments' && <SentDocuments setView={setView} /> }
            <Documents />
        </div>
    </div>;
}

export default Patient;