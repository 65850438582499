import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const PersonalDataForm = () => {
    const [patientAreaActivated, setPatientAreaActivated] = useState(false);

    function handleCreatePermanentPatientArea(e) {
        setPatientAreaActivated(e.target.checked);
    }

    useEffect(() => {
        const element = document.getElementById('sendLoginData');

        if (patientAreaActivated) {
            element?.classList.remove('disabled');
        } else {
            element?.classList.add('disabled');
        }
    }, [patientAreaActivated]);

    return <form>
        <h2>Allgemeine Daten</h2>
        <div className="image-section-wrapper">
            <a className="addImage" href=''>Foto hinzufügen:</a>
            <img src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" />
            <a className="editImage" href=''>Bearbeiten</a>
        </div>
        <table id="table-general-data">
            <tr>
                <td>Anrede</td>
                <td>
                    <select name="salutation" id="salutation">
                        <option value="notSpecified">Keine Angaben</option>
                        <option value="female">Frau</option>
                        <option value="male">Herr</option>
                        <option value="diverse">Divers</option>
                    </select>
                    <select name="title" id="title">
                        <option value="notSpecified">Keine Angaben</option>
                        <option value="Prof.">Prof.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Prof.Dr.">Prof.Dr.</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Vorname</td>
                <td><input type="text" name="firstname" placeholder="" /></td>
            </tr>
            <tr>
                <td>Nachname</td>
                <td><input type="text" name="lastname" placeholder="" /></td>
            </tr>
            <tr>
                <td>Bio. Geschlecht</td>
                <td>
                    <select name="biologicalGender" id="biologicalGender">
                        <option value="female">Weiblich</option>
                        <option value="male">Männlich</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Geschlechtsidentität</td>
                <td>
                    <select name="genderIdentity" id="genderIdentity">
                        <option value="diverse">Divers</option>
                        <option value="female">Weiblich</option>
                        <option value="male">Männlich</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Geburtstag</td>
                <td><input type="date" name="dateofbirth" id="dateofbirth" /></td>
            </tr>
            <tr>
                <td>Versichertennummer</td>
                <td>
                    <input type="text" name="healthInsuranceNumber" placeholder="Versichertennummer" />
                    <input type="text" name="healthInsurance" placeholder="Krankenkasse" />
                </td>
            </tr>
            <tr>
                <td>Straße/Hausnr.</td>
                <td>
                    <input type="text" name="street" />
                    <input type="text" name="houseNumber" />
                </td>
            </tr>
            <tr>
                <td>c/o</td>
                <td>
                    <input type="text" name="addressCo" />
                </td>
            </tr>
            <tr>
                <td>PLZ/Stadt</td>
                <td>
                    <input type="text" name="zip" />
                    <input type="text" name="city" />
                </td>
            </tr>
            <tr>
                <td>Land</td>
                <td>
                    <select name="country" id="country">
                        <option value="DE">Deutschland</option>
                        <option value="AU">Österreich</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>E-Mail-Adresse</td>
                <td>
                    <input type="email" name="email" />
                </td>
            </tr>
            <tr>
                <td>Telefon/Handy</td>
                <td>
                    <input type="phone" name="phone" />
                </td>
            </tr>
            <tr>
                <td>Behandlungsstatus</td>
                <td>
                    <select name="treatmentStatus" id="treatmentStatus">
                        <option value="active">Aktiv</option>
                        <option value="inactive">Inaktiv</option>
                    </select>
                </td>
            </tr>
        </table>

        <h2>System-Daten</h2>
        <table id="table-system-data">
            <tr>
                <td><h3>Datenabfrage</h3></td>
            </tr>
            <tr>
                <td>Einmalige Datenabfrage durch Patient</td>
                <td><button className="ny-modal-trigger ny-form-btn" ny-target-modal="ny-patient-page-data-submission-modal">Abfrage</button></td>
            </tr>
            <tr>
                <td>Status der letzten Datenabfrage</td>
                <td>Genutzt am 01.01.2021 um 09:00&nbsp;Uhr </td>
            </tr>
            <tr>
                <td><h3>Patienten Bereich</h3></td>
            </tr>
            <tr>
                <td>Dauerhaften Patienten-Bereich einrichten</td>
                <td><input type="checkbox" onChange={(e) => {handleCreatePermanentPatientArea(e)}} className="ny-form-check" name="createPermanentPatientArea"></input></td>
            </tr>
            <tr>
                <td>Login Daten senden</td>
                <td><a id="sendLoginData" className="ny-form-btn">Senden</a> <span>Gesendet:&nbsp;01.03.2021</span></td>
            </tr>
        </table>

        <div className='modal-footer'>
            <p className="ny-modal-trigger ny-modal-close" ny-target-modal="ny-patient-page-profile-modal">&lt; Abbrechen</p>
            <img id="ny-save-form" className="saveIcon" src="/icons/save.svg" alt="Icon" />
        </div>
        <div className="ny-modal-content_underline"></div>
    </form>
}

export default PersonalDataForm;