import React from "react";

function ModalImage(props) {
    function handleCancelButton() {
        document.querySelectorAll('.ny-modal').forEach((element) => {
            element.classList.remove('visible');
        });
    }

    return <div className="actionModal">
        <div className="actionButtons">
            <div className="action delete">Foto löschen</div>
            <div className="action">Foto aufnehmen</div>
            <div className="action">Foto auswählen</div>
        </div>
        <div className="cancel ny-modal-trigger" onClick={() => {handleCancelButton()}}>Abbrechen</div>
    </div>
}

export default ModalImage;