import React from "react";

import Modal from "../../Modal";
import ModalImage from "./ModalImage";

function UserData() {
    const profileImageExist = true;
    const logoImageExist = true;

    return <div className="tab UserData">
        <div className="officeData">
            <h3>Praxisdaten</h3>
            <div className="imageWrapper">
                <div className="left">
                    Foto hinzufügen
                </div>
                <div className="right image">
                    <img className="" src="/icons/profileImage.png" alt="Profile"/>
                    <span className="ny-modal-trigger" ny-target-modal="ny-settings-page-profile-image-modal">Bearbeiten</span>
                </div>
                {<Modal modalId="ny-settings-page-profile-image-modal">
                    <ModalImage profileImageExist={profileImageExist} />
                </Modal>}
            </div>
            <div className="group">
                <div className="row">
                    <div className="left">Anrede</div>
                    <div className="right">
                        <select name="salutation" id="salutation">
                            <option value="notSpecified">Keine Angaben</option>
                            <option value="female">Frau</option>
                            <option value="male">Herr</option>
                            <option value="diverse">Divers</option>
                        </select>
                        <select name="title" id="title">
                            <option value="notSpecified">Keine Angaben</option>
                            <option value="Prof.">Prof.</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Prof.Dr.">Prof.Dr.</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="left">Vorname</div>
                    <div className="right"><input type="text" name="firstname" /></div>
                </div>
                <div className="row">
                    <div className="left">Nachname</div>
                    <div className="right"><input type="text" name="lastname" /></div>
                </div>
                <div className="row">
                    <div className="left">Firma</div>
                    <div className="right"><input type="text" name="company" /></div>
                </div>
            </div>
            <h3>Rechnungsanschrift</h3>
            <div className="group">
                <div className="row">
                    <div className="left">Straße/Hausnr.</div>
                    <div className="right">
                        <input type="text" name="street" />
                        <input type="text" name="houseNumber" />
                    </div>
                </div>
                <div className="row">
                    <div className="left">PLZ / Stadt</div>
                    <div className="right"><input type="text" name="zip" /><input type="text" name="city" /></div>
                </div>
                <div className="row">
                    <div className="left">Land</div>
                    <div className="right">
                        <select name="country" id="country">
                            <option value="DE">Deutschland</option>
                            <option value="AU">Österreich</option>
                        </select>
                    </div>
                </div>
            </div>
            <h3>Kommunikation</h3>
            <div className="group">
                <div className="row">
                    <div className="left">E-Mail-Adresse</div>
                    <div className="right"><input type="text" name="email" /></div>
                </div>
                <div className="row">
                    <div className="left">Telefon</div>
                    <div className="right"><input type="text" name="phone" /></div>
                </div>
                <div className="row">
                    <div className="left">Handy</div>
                    <div className="right"><input type="text" name="mobilePhone" /></div>
                </div>
                <div className="row">
                    <div className="left">Website</div>
                    <div className="right"><input type="text" name="website" /></div>
                </div>
            </div>
        </div>
        <div className="otherData">
            <h3>Sonstige Daten</h3>
            <div className="imageWrapper">
                <div className="left">
                    Logo hinzufügen
                </div>
                <div className="right image">
                    <img className="" src="/images/nibyou_logo.png" alt="Logo"/>
                    <span className="ny-modal-trigger" ny-target-modal="ny-settings-page-logo-image-modal">Bearbeiten</span>
                </div>
                {<Modal modalId="ny-settings-page-logo-image-modal">
                    <ModalImage logoImageExist={logoImageExist} />
                </Modal>}
            </div>
            <div className="group">
                <div className="row">
                    <div className="left">Qualifikationsnachweis hinzufügen</div>
                    <div className="right">
                        <div className="label"><span>Qualifikationsnachweis1.pdf</span></div>
                        <img className="" src="/icons/trash.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right">
                        <div className="label"><span>Qualifikationsnachweis2.pdf</span></div>
                        <img className="" src="/icons/trash.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right"><img className="" src="/icons/plus.svg" alt="Icon"/></div>
                </div>
            </div>
            <div className="group">
                <div className="row">
                    <div className="left">Beruflicher Werdegang</div>
                    <div className="right">
                        <div className="label"><span>Diätassitent*in</span></div>
                        <img className="" src="/icons/trash.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right">
                        <div className="label"><span>Diplom-Pädagoge*in</span></div>
                        <img className="" src="/icons/trash.svg" alt="Icon"/>
                    </div>
                </div>
                <div className="row">
                    <div className="right"><img className="" src="/icons/plus.svg" alt="Icon"/></div>
                </div>
            </div>
            <div className="group">
                <div className="row">
                    <div className="left">Nibyou ID</div>
                    <div className="right">
                        <img className="" src="/icons/copy.svg" alt="Icon"/>
                        <span>TGXKJ7834</span>
                    </div>
                </div>
                <div className="row">
                    <div className="left">Nibyou ID neu generieren</div>
                    <div className="right"><img className="" src="/icons/refresh.svg" alt="Icon"/></div>
                </div>
            </div>
            <h3>Backups</h3>
            <div className="group">
                <div className="row">
                    <div className="left">Lokalen Backup machen</div>
                    <div className="right">
                        <img className="" src="/icons/backup.svg" alt="Icon"/>
                        <span>Letztes Backup: 14.10.1999</span>
                    </div>
                </div>
                <div className="row">
                    <div className="left">Backup wiederherstellen</div>
                    <div className="right"><img className="" src="/icons/refresh.svg" alt="Icon"/></div>
                </div>
            </div>
        </div>
    </div>;
}

export default UserData;