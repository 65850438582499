import React from "react";

function Invitations() {
    return <div className="column Invitations">
        <div className="invitationsWrapper">
            <h2>Einladungen</h2>
        </div>
    </div>;
}

export default Invitations;